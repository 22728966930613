import React, { useEffect } from "react";
import LocationTracker from "./shared/analytics/LocationTracker";

function MicroFrontend({ history, host, microFrontendUiName }) {
    useEffect(() => {
        const scriptId = `micro-frontend-script-${microFrontendUiName}`;

        const renderMicroFrontend = () => {
            const renderFunctionName = `render${microFrontendUiName}`;
            const renderFunction = window[renderFunctionName];
            if (!renderFunction) {
                console.warn("Render function not found: ", renderFunctionName, window);
            }
            renderFunction(`${microFrontendUiName}-container`, history, {
                history,
                locationTracker: LocationTracker,
                host
            });
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            return;
        }

        fetch(`${host}/asset-manifest.json`)
            .then((res) => res.json())
            .then((manifest) => {
                const script = document.createElement("script");
                script.id = scriptId;
                script.crossOrigin = "";
                script.src = `${host}${manifest.files["main.js"]}`;
                script.onload = () => {
                    renderMicroFrontend();
                };
                document.head.appendChild(script);

                const style = document.createElement("link");
                style.href = `${host}${manifest.files["main.css"]}`;
                style.rel = "stylesheet";
                document.head.appendChild(style);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host, microFrontendUiName]);

    useEffect(() => {
        return () => {
            window[`unmount${microFrontendUiName}`] && window[`unmount${microFrontendUiName}`](`${microFrontendUiName}-container`);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div class="layout">
                <LocationTracker microFrontend={microFrontendUiName} />
                <main class="content" id={`${microFrontendUiName}-container`} />
            </div>;
}

MicroFrontend.defaultProps = {
    document,
    window,
};

export default MicroFrontend;
